
import { defineComponent, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'

export default defineComponent({
  name: 'OnlineComplete',
  components: {
    DssIcon,
    AppButton,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const receiptNumber = route.params.receiptNumber
    onMounted(async () => {
      if (receiptNumber === undefined) {
        await router.push('/dms/online/agreement')
      }
    })
    return {
      router,
      receiptNumber,
    }
  },
})
